<template>
  <div class="steps">
    <div
      v-for="(step, index) in steps"
      :key="step.index"
      class="steps__step"
      :id="'authoringTab' + index"
      :class="{
        'steps__step--in-progress': stepIndex === step.index,
        'steps__step--completed': stepIndex > step.index,
        'steps__step--incompleted': stepIndex < step.index,
        'steps__step--half-width': steps.length === 4,
      }"
      @click="moveStep(step)"
    >
      <div class="icon"><img :src="step.icon" /></div>
      <div class="title">{{ step.title }}</div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import * as jsUtils from "@/utils/jsUtils.js";

export default {
  name: "ProgressBar",
  props: {
    stepIndex: {
      type: Number,
      default() {
        return 1;
      },
    },
    stepsType: {
      type: String,
      default() {
        return "video";
      },
    },
    surveyID: [Number, String],
  },
  data() {
    return {
      surveyTypeID: null,
      videoTestSteps: [
        {
          title: "General Settings",
          icon: "/images/icons/config.svg",
          index: 1,
          route: "vid-setting",
        },
        {
          title: "Creative",
          icon: "/images/icons/video2.svg",
          index: 2,
          route: "vid-creative",
        },
        {
          title: "Brand Strategy",
          icon: "/images/icons/video3.svg",
          index: 3,
          route: "vid-brand-strategy",
        },
        {
          title: "Key Metrics",
          icon: "/images/icons/video4.svg",
          index: 4,
          route: "vid-key-metrics",
        },
        {
          title: "Audience",
          icon: "/images/icons/video5.svg",
          index: 5,
          route: "vid-audience",
        },
        {
          title: "Launch",
          icon: "/images/icons/launch.svg",
          index: 6,
          route: "vid-launch",
        },
      ],
      packTestSteps: [
        {
          title: "General Settings",
          icon: "/images/icons/config.svg",
          index: 1,
          route: "pack-test-setting",
        },
        {
          title: "Stimuli",
          icon: "/images/icons/stimuli.svg",
          index: 2,
          route: "stimuli",
        },
        {
          title: "Brand Strategy",
          icon: "/images/icons/video3.svg",
          index: 3,
          route: "communication-objective",
        },
        {
          title: "Launch",
          icon: "/images/icons/launch.svg",
          index: 6,
          route: "pack-test-launch",
        },
      ],
      steps: [],
      user: null,
    };
  },
  computed: {},
  methods: {
    getSurveyData() {
      let wsType;

      if (this.stepsType === "Pack Test") {
        wsType = "GetPackTestSurveyDetails";
      } else if (this.stepsType === "Reticle Alignment Test") {
        wsType = "GetSurveyStimReticle";
      } else {
        wsType = "GetCombineSurveyDetails";
      }
      const surveyPromise = new Promise((resolve) => {
        wsUtils[wsType](
          {
            SurveyID: this.surveyID,
            SurveyTypeID: jsUtils.getSurveyTypeId(),
          },
          resolve
        );
      });
      surveyPromise.then((data) => {
        jsUtils.setEditableSurvey(data);
      });
    },
    moveStep(step) {
      let ssoLogin = jsUtils.readCookie("sso-login");
      if (
        this.user.dashboardURL !== "" &&
        step.index === 1 &&
        ssoLogin !== ""
      ) {
        let ssoData = JSON.parse(jsUtils.readCookie("sso-login"));
        window.open(
          `https://d11qiq41szmpd8.cloudfront.net/hotspex/project/${ssoData.pgprojectid}`,
          "_self",
          "noreferrer"
        );
      } else {
        this.getSurveyData();
        this.$router.push({
          name: `${step.route}`,
          params: { id: this.surveyID },
        });
      }
    },
  },
  activated() {
    this.surveyTypeID = jsUtils.getSurveyTypeId();
    this.user = jsUtils.getUserInfo();
    if (this.$route.meta.progressBarSteps) {
      this.steps = this.$route.meta.progressBarSteps;
    } else if (this.stepsType === "video") {
      this.steps = this.videoTestSteps;
    } else if (this.stepsType === "pack") {
      this.steps = this.packTestSteps;
    }
  },
  created() {
    if (this.stepsType === "pack") {
      window.Hopscotch("tour.start", { tourId: 154 });
    }
  },
};
</script>

<style scoped lang="scss">
$medium: 1100px;
.steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  column-gap: 1px;
  padding: 0 20px;
  align-items: start;
  @media screen and (max-width: 980px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 800px) {
    column-gap: 0;
  }

  &__step {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    padding: 0 1em;
    text-align: left;
    column-gap: 10px;
    min-height: 50px;
    background-color: var(--grey500);

    @media screen and (max-width: $medium) {
      padding: 0 0.5em;
    }
    @media screen and (max-width: 980px) {
      flex: 1;
    }
    @media screen and (max-width: 800px) {
      flex: 0 0 33.33%;
      min-height: 40px;
      border-right: 1px solid #cbcbcb;
      border-bottom: 1px solid #cbcbcb;
    }

    .icon {
      width: 32px;
      height: 32px;

      @media screen and (max-width: $medium) {
        width: 24px;
        height: 24px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .title {
      color: #fff;
      font-size: 0.9em;
      @media screen and (max-width: 980px) {
        font-size: 13px;
        line-height: 15px;
      }
      @media screen and (max-width: 800px) {
        font-size: 12px;
        line-height: 14px;
      }
    }

    &--half-width {
      @media screen and (max-width: 800px) {
        flex: 0 0 50%;
      }
    }

    &--in-progress {
      padding: 1em;
      background-color: var(--hsyellow);
      border-radius: 0 0 8px 8px;

      @media screen and (max-width: $medium) {
        padding: 1em 0.5em;
      }
      @media screen and (max-width: 800px) {
        padding: 0 0.5em;
        border-radius: 0;
      }

      .icon {
        width: 40px;
        height: 40px;
        filter: invert(1);
        transition: background-color 0.4s;

        @media screen and (max-width: $medium) {
          width: 32px;
          height: 32px;
        }
        @media screen and (max-width: 980px) {
          width: 22px;
          height: 22px;
        }
      }

      .title {
        transition: color 0.4s;
        font-size: 1em;
        color: #333;
        font-weight: bold;
        @media screen and (max-width: 980px) {
          font-size: 13px;
        }
        @media screen and (max-width: 800px) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    &--completed {
      transition: all 2s;
      // color: var(--succeedGreen);
      background-color: var(--hsblue);
      cursor: pointer;

      .icon {
        transition: background-color 0.4s;
      }

      .title {
        transition: color 0.4s;
      }
    }

    &--incompleted {
      pointer-events: none;
    }
  }
}
</style>
